import React from 'react';
import styled from 'styled-components';
import { colors, devices } from '../assets/variables';
import '../assets/style/global.scss'
import { Col, Content, Row } from '../sections/UI';
import Footer from '../components/Footer';
import PageHero from '../components/PageHero';
import software001 from '../assets/images/jpg/software001.jpg';
import software002 from '../assets/images/jpg/software002.jpg';
import Button from '../components/Button';
import FooterHero from '../components/FooterHero';
import { Fade } from "react-awesome-reveal";

const Section = styled.div`
    background-color: ${colors.White};
    color: ${colors.Primary};
    & .wrap { align-items: start; }
    & p { padding-bottom: .5rem; }
    @media (max-width: ${devices.tablet}) {
        & .maxw-30,
        & .maxw-40,
        & .maxw-50,
        & .maxw-60,
        & .maxw-70 { max-width: 100% !important; }
        & .wrap { justify-content: center; }
    }
`;

const Image = styled.img`
    /* box-shadow:0px 0px 0px 3px ${colors.Color4} inset;
    padding: 3px; */
    @media (max-width: ${devices.tablet}) {
        &.maxw-35 { max-width: calc(100% - 6px) !important; }
    }
`;

function SoftwareAndServices() {
  return (
    <>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <PageHero
        title="Software and Services"
        description="EntraLinq Plus extends the app’s capabilities, offering advanced features and a heightened level of control over Ace Solutions products, making it a valuable investment for users with more extensive requirements."
      />
    </Fade>
    <Section>
      <Content className='pv-20'>
        <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
          <Row className='wrap'>
            <h2 className='maxw-70 pb-20'>EntraLinq app provides full control of your Ace Solutions product from anywhere</h2>
            <Button link={'/shop'} className='mb-20'>Our offering</Button>
          </Row>
          <p><b>EntraLinq</b> by <b>Ace Solutions</b> is an advanced access control solution designed to streamline and enhance security management for various environments. This comprehensive platform integrates mobile apps, sophisticated software, and a range of services to provide a seamless, secure, and user-friendly access control experience.</p>
          <Row className='wrap mt-20'>
            <div className='maxw-60 pb-10'>
              <h3 className='pb-10'>Key Features of EntraLinq:</h3>
              <ol>
                <li>
                  <b>Unified Access Control System</b>:
                  <ul>
                    <li><b>Centralized Management</b>: <b>EntraLinq</b> offers a centralized platform to manage access control across multiple sites and entry points, simplifying security administration.</li>
                    <li><b>Flexible Authentication Methods</b>: Supports various authentication methods such as RFID cards, mobile credentials, biometrics (fingerprint and facial recognition), and QR codes, providing flexibility and enhanced security.</li>
                  </ul>
                </li>
                <li>
                  <b>Real-Time Monitoring and Alerts</b>:
                  <ul>
                    <li><b>Live Tracking</b>: The platform provides real-time tracking of access points, allowing security personnel to monitor entries and exits as they happen.</li>
                    <li><b>Instant Notifications</b>: Immediate alerts are sent in case of unauthorized access attempts, breaches, or other security incidents, enabling quick response.</li>
                  </ul>
                </li>
                <li>
                  <b>User-Friendly Interface</b>:
                  <ul>
                    <li><b>Mobile App</b>: The <b>EntraLinq</b> mobile app allows users to manage access control remotely, offering convenience and flexibility for security managers and administrators.</li>
                    <li><b>Dashboard and Reporting</b>: The software features an intuitive dashboard that provides detailed reports and analytics on access patterns, helping make informed security decisions.</li>
                  </ul>
                </li>
                <li>
                  <b>Scalability and Integration</b>:
                  <ul>
                    <li><b>Modular Design</b>: <b>EntraLinq</b> is designed to be scalable, allowing businesses to add new features and expand the system as their needs grow.</li>
                    <li><b>System Integration</b>: It can be integrated with existing security systems and other <b>Ace Solutions</b> solutions, ensuring a seamless and comprehensive security infrastructure.</li>
                  </ul>
                </li>
                <li>
                  <b>Enhanced Security Features</b>:
                  <ul>
                    <li><b>Visitor Management</b>: The platform includes visitor management capabilities, allowing for the registration, tracking, and management of visitors efficiently.</li>
                    <li><b>Zone Management</b>: Security zones can be defined, and access can be restricted based on user roles and permissions, enhancing security in sensitive areas.</li>
                  </ul>
                </li>
              </ol>
            </div>
            <Image className='maxw-35' src={software001} alt="" />
          </Row>
          <Row className='wrap reverse mv-20'>
            <div className='maxw-60 mb-20'>
              <h3 className='pb-10'>Applications of EntraLinq:</h3>
              <ol>
                <li>
                  <b>Corporate Offices</b>:
                  <ul>
                    <li><b>Employee Access Management</b>: Streamlines employee access, ensuring that only authorized personnel can enter specific areas.</li>
                    <li><b>Visitor Handling</b>: Efficiently manages visitor registration and access, improving security and convenience.</li>
                  </ul>
                </li>
                <li>
                  <b>Healthcare Facilities</b>:
                  <ul>
                    <li><b>Patient and Staff Security</b>: Manages access to critical areas such as patient wards, pharmacies, and administrative offices.</li>
                    <li><b>Emergency Response</b>: Provides tools for quick access management and monitoring during emergency situations.</li>
                  </ul>
                </li>
                <li>
                  <b>Educational Institutions</b>:
                  <ul>
                    <li><b>Student and Faculty Access</b>: Controls access to classrooms, labs, and administrative offices, ensuring a secure campus environment.</li>
                    <li><b>Event Management</b>: Manages access for events and special occasions, ensuring that only authorized individuals can enter event-specific zones.</li>
                  </ul>
                </li>
                <li>
                  <b>Public Transport and Airports</b>:
                  <ul>
                    <li><b>Passenger Flow Management</b>: Controls access points to manage passenger flow efficiently and securely.</li>
                    <li><b>Staff Access Control</b>: Ensures that only authorized staff can access restricted areas, enhancing overall security.</li>
                  </ul>
                </li>
              </ol>
            </div>
            <Image className='maxw-35' src={software002} alt="" />
          </Row>
          <h3 className='mt-20 pb-10'>Benefits of EntraLinq:</h3>
          <ul>
            <li><b>Improved Security</b>: Provides robust access control and real-time monitoring, enhancing overall security for the premises.</li>
            <li><b>Operational Efficiency</b>: Simplifies access management processes, reducing administrative burden and improving operational efficiency.</li>
            <li><b>Scalability</b>: The modular design allows businesses to scale the system according to their growing needs.</li>
            <li><b>Data-Driven Decisions</b>: Offers detailed analytics and reports, enabling data-driven decisions to optimize security strategies.</li>
            <li><b>User Convenience</b>: The mobile app and user-friendly interface provide convenience for both administrators and users, enhancing the overall experience.</li>
          </ul>
          <h3 className='mt-20 pb-10'>Services Offered by Ace Solutions with EntraLinq:</h3>
          <ol>
            <li>
              <b>Implementation and Integration</b>:
              <ul>
                <li><b>Consultation and Planning</b>: <b>Ace Solutions</b> provides expert consultation to understand the specific needs of a business and plan the implementation of <b>EntraLinq</b> accordingly.</li>
                <li><b>Seamless Integration</b>: Ensures that <b>EntraLinq</b> integrates smoothly with existing security systems and infrastructure.</li>
              </ul>
            </li>
            <li>
              <b>Training and Support</b>:
              <ul>
                <li><b>User Training</b>: Comprehensive training sessions for users and administrators to ensure effective use of the platform.</li>
                <li><b>Ongoing Support</b>: Continuous support and maintenance services to ensure the system operates smoothly and any issues are promptly addressed.</li>
              </ul>
            </li>
            <li>
              <b>Customization Services</b>:
              <ul>
                <li><b>Tailored Solutions</b>: Customizes the <b>EntraLinq</b> platform to meet the specific requirements of different industries and business environments.</li>
              </ul>
            </li>
          </ol>
          <h3 className='mt-20 pb-10'>Conclusion</h3>
          <p><b>Ace Solutions</b>’s <b>EntraLinq</b> is a powerful and flexible access control solution that combines advanced software, a user-friendly app, and comprehensive services. It enhances security, streamlines operations, and provides valuable insights for effective security management. With its scalability and integration capabilities, <b>EntraLinq</b> is an ideal solution for businesses looking to enhance their access control and security infrastructure.</p>
          <Col className='mv-20'>
            <Button link={'/shop'} className='mt-20'>Our offering</Button>
          </Col>
        </Fade>
      </Content>
    </Section>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <FooterHero />
    </Fade>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <Footer />
    </Fade>
    </>
  );
}

export default SoftwareAndServices;
