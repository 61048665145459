import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { colors, devices } from '../assets/variables';
import { Col, Content, Row } from '../sections/UI';
import oss from '../assets/images/png/our-software-suite.png';
import fe from '../assets/images/png/safepay-collect-front-end.png';
import fbe from '../assets/images/png/safepay-collect-front-and-back-end.png';
import Button from './Button';
import { Link } from 'react-router-dom';

const P = styled.p`
    max-width: 40%;    
    @media (max-width: ${devices.tablet}) {
        max-width: 100%;
    }
`;

const Products = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: stretch;
    gap: 1rem;
    @media (max-width: ${devices.tablet}) {
        max-width: 100% !important;
    }
`;

const Card = styled(Link)`
    flex: 1;
    display: flex;
    flex: 1 1 150px;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    padding: 1rem;
    background-color: ${colors.TransparentWhite};
    transition: all .3s ease-in;   
    &:hover {
        background-color: ${colors.White};
        & p {
            color: ${colors.Primary};
        }
    }

    & img {
        height: 150px;
    }

    & p {
       font-weight: 400; 
    }
`;

const ProductCategories = () => {
    
    return (
        <>
            <Content>
                <Col>
                    <h2 className='mt-30 mb-30 text-center'>Closed Cash Management Product Range</h2>
                    <P className='pb-30 text-center'>The key characteristic of a closed cash management system is that it keeps cash locked away for the entire duration of its lifecycle in a store. </P>
                    <Products className='maxw-50 pb-30'>
                        <Card to={'/'}>
                            <img src={oss} alt="" />
                            <p className='pt-20 text-center'>Our Software Suite</p>
                        </Card>
                        <Card to={'/'}>
                            <img src={fe} alt="" />
                            <p className='pt-20 text-center'>SafePay Collect. Front-end</p>
                        </Card>
                        <Card to={'/'}>
                            <img src={fbe} alt="" />
                            <p className='pt-20 text-center'>SafePay Collect. Front- and Back-end</p>
                        </Card>
                    </Products>
                    <Button link={'/shop'} className="mb-30 pulse">More products</Button>
                </Col>
            </Content>
        </>
    );
}

export default ProductCategories;
