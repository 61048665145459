import React from 'react';
import styled from 'styled-components';
import { colors } from '../assets/variables';
import { Link } from 'react-router-dom';
import home_hero from '../assets/images/jpg/home_hero.jpg';
import { Content } from '../sections/UI';

const Btn = styled(Link)`
    background-color: ${colors.Color1};
    color: ${colors.White};
    padding: .5rem 1.5rem;
    cursor: pointer;
    white-space: nowrap;
    transition: all .4s ease-in;    
    text-align: center;
    border: 2px solid ${colors.Color1};
    &.dark {
      background-color: ${colors.Color0};
    }
    &.transparent {
      background-color: rgba(0,0,0,0.1);
    }
    &.pulse {
      box-shadow: 0 0 0 0 rgba(88, 120, 243, 0.4);
      -moz-animation: pulse 2s infinite;
      -webkit-animation: pulse 2s infinite;
      animation: pulse 2s infinite;
    }
    &:hover {
      background-color: ${colors.White};
      color: ${colors.Black} !important;
      border: 2px solid ${colors.White};
      &.pulse {
        box-shadow: none;
        -moz-animation: none;
        -webkit-animation: none;
        animation: none;
      }
    }
`;

function Button({link, className, children}) {
  return (
    <Btn className={className} to={link}>
        {children}
    </Btn>
  );
}

export default Button;
