import React, { useEffect, useState, useRef } from 'react';
import Slider from "react-slick";
import styled from 'styled-components';
import { colors, devices } from '../assets/variables';

import slide_cash_management1 from '../assets/images/svg/slide-cash-management1.svg';
import slide_cash_management2 from '../assets/images/svg/slide-cash-management2.svg';

import slide_iot_solutions1 from '../assets/images/svg/slide-iot-solutions1.svg';
import slide_iot_solutions2 from '../assets/images/svg/slide-iot-solutions2.svg';

import slide_physical_security1 from '../assets/images/svg/slide-physical-security1.svg';
import slide_physical_security2 from '../assets/images/svg/slide-physical-security2.svg';

import slide_software_and_services1 from '../assets/images/svg/slide-software-and-services1.svg';
import slide_software_and_services2 from '../assets/images/svg/slide-software-and-services2.svg';

import { Col, Content, Row } from '../sections/UI';

const MenuCarousel = styled(Slider)`
    display: block;
    width: 100%;
    height: 100%;
    & .slick-slide {
        color: ${colors.White};
        opacity: 0.1;
        z-index: 1;
    }
    & .slick-center {
        color: ${colors.Color1};
        opacity: 1;
        z-index: 5;
    }
    & .slick-track {
        align-items: center;
    }
`;

const MenuSlide = styled.div`
    display: flex!important;
    flex-direction: row;
    justify-content: space-evenly;
    white-space: nowrap;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    line-height: 5rem;
    font-weight: 900;
    text-transform: uppercase;
    border-bottom: 2px solid ${colors.Color1};
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }
    & .col {
        flex: 1;
    }
    @media (max-width: ${devices.tablet}) {
        font-size: 1.5rem;
        line-height: 5rem;
    }
`;

const ContentCarousel = styled(Slider)`
    display: block;
    position: relative;
    margin: 0px auto;

    .slick-dots {
        bottom: 0rem;
        z-index: 5;
        li {
            width: auto;
            height: 5px;
            & .dot {
                background-color: ${colors.TransparentWhite};
                width: 5vw;
                height: 3px;
                border-radius: 50px;
            }
            &.slick-active .dot {
                background-color: ${colors.Color1};
            }
        }
    }
`;

const Slide = styled(Content)`
    padding: 1rem;
    display: flex !important;
    flex-direction: row;
    gap: 0px;
    @media (max-width: ${devices.tablet}) {
        flex-direction: column;
    }
`;

const Image = styled.img`
    width: 50%;
    min-height: 1px;
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    @media (max-width: ${devices.tablet}) {
        width: calc(100% - 2rem);
    }
`;

const ProductsCarousel = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    let sliderRefMenu = useRef(null);
    let sliderRefContent = useRef(null);
    var menuSettings = {
        dots: false,
        fade: false,
        infinite: true,
        lazyLoad: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
        arrows: false,
        className: "center",
        centerMode: true,
        centerPadding: "60px",
        slidesToShow: 3,        
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1
                }
            }
        ],
        afterChange: (current, next) => sliderRefContent.slickGoTo(current),
        speed: 500
    };
    var contentSettings = {
        dots: false,
        fade: true,
        infinite: true,
        lazyLoad: true,
        swipeToSlide: false,
        swipe: false,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        cssEase: "linear"
    };
    
    return (
        <>
        <MenuCarousel
        ref={slider1 => {
            sliderRefMenu = slider1;
        }}
        {...menuSettings}>
            <MenuSlide>Physical Security</MenuSlide>
            <MenuSlide>Cash Management</MenuSlide>
            <MenuSlide>Software and Services</MenuSlide>
            <MenuSlide>IoT Solutions</MenuSlide>
        </MenuCarousel>
        <ContentCarousel
        className='preventDotsEvent mt-35 pb-30'
        ref={slider2 => {
            sliderRefContent = slider2;
        }}
        {...contentSettings}>
            <Slide>
                <Image src={slide_physical_security1} />
                <Image src={slide_physical_security2} />
            </Slide>
            <Slide>
                <Image src={slide_cash_management1} />
                <Image src={slide_cash_management2} />
            </Slide>
            <Slide>
                <Image src={slide_software_and_services1} />
                <Image src={slide_software_and_services2} />
            </Slide>
            <Slide>
                <Image src={slide_iot_solutions1} />
                <Image src={slide_iot_solutions2} />
            </Slide>
        </ContentCarousel>
        </>
    );
}

export default ProductsCarousel;
