import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors, devices } from '../assets/variables';
import { Section, Content, Row } from '../sections/UI'
import logoGold from '../assets/images/svg/logo-gold.svg';
import logoWhite from '../assets/images/svg/logo-white.svg';
import logoBlack from '../assets/images/svg/logo-black.svg';
import { Link } from 'react-router-dom';
import Button from './Button';
import { Spin as Hamburger } from 'hamburger-react';

const Heading = styled.div`
    background: ${colors.Gradient1};
    position: fixed;
    padding: 1rem 0;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 50;
    transition: all .4s ease-in;   

    &.scrolled {
        padding: .2rem 0 1rem 0;
        .logo {
            max-height: 1rem;
        }
        .button {
            padding: .4rem 1.5rem;
        }
    }

    @media (max-width: ${devices.tablet}) {
        padding: 0;
        
        &.scrolled {
            padding: 0rem;
        }
    }
`;

const Nav = styled.div`
    .logo {
        width: 100%;
        max-height: 1.2rem;
        transition: all .4s ease-in;   
    }
    .nav {
        gap: 1rem;
        & a, & a:visited {
            font-size: 1rem;
            color: ${colors.White};
            &:hover {
                color: ${colors.Color1};
            }
        }
        & .hamburger-react {
            display: none;
            z-index: 1000;
        }
    }
    
    @media (max-width: ${devices.tablet}) {
        .nav {
            .link { display: none; }
            .hamburger-react { display: block !important; }
        }
    }
    
    @media (max-width: ${devices.mobile}) {
        .nav {
            .button { display: none; }
        }
    }
`;

const Sidebar = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: calc(100% + 700px);
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: row-reverse;
    z-index: 900;
    display: none;
    &.open {
        display: flex;
        left: -700px;
    }
    & a, & a:visited {
        color: ${colors.White};
        &:hover {
            color: ${colors.Black};
        }
        &.button {
        }
    }
`;

const Menu = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 15px;
    justify-content: center;
    background-color: ${colors.Color1};
    width: 220px;
`;

function Header() {
    const [isOpen, setOpen] = useState(false);
    const [isScrolled, setScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
      if(window.scrollY > 250) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    return (
        <Heading className={`${isScrolled && 'scrolled'}`}>
            <Content>
                <Nav>
                    <Row>
                        <Link to={'/'}><img className='logo' src={logoWhite} alt='' /></Link>
                        <Row className='nav'>
                            <Link className='link' to={'/PhysicalSecurity'}>Physical Security</Link>
                            <Link className='link' to={'/CashManagement'}>Cash Management</Link>
                            <Link className='link' to={'/SoftwareAndServices'}>Software and Services</Link>
                            <Link className='link' to={'/IoTSolutions'}>IoT Solutions</Link>
                            <Hamburger size={20} toggled={isOpen} toggle={setOpen} />
                            <Button className='button' link={'tel:+4722900300'}>Get in touch</Button>
                        </Row>
                    </Row>
                </Nav>
            </Content>
            <Sidebar className={isOpen && 'open'}>
                <Menu onClick={() => setOpen(false)}>
                    <Link to={'/'}>Home</Link>
                    <Link to={'/PhysicalSecurity'}>Physical Security</Link>
                    <Link to={'/CashManagement'}>Cash Management</Link>
                    <Link to={'/SoftwareAndServices'}>Software and Services</Link>
                    <Link to={'/IoTSolutions'}>IoT Solutions</Link>
                    <Link to={'tel:+4722900300'}>Get in touch</Link>
                </Menu>
            </Sidebar>
        </Heading>
    );
}

export default Header;