import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { colors, devices } from '../assets/variables';
import { Col, Content, HR, Row } from '../sections/UI';
import map from '../assets/images/jpg/map.jpg';
import source from '../assets/images/png/footer.png';
import Button from './Button';
import Input from './Input';
import { Link } from 'react-router-dom';
import ScrollBar from './ScrollBar';

const Map = styled.div`
  background: ${colors.Color0} url(${map}) center center no-repeat;
  background-size: cover;
  height: 30vw;
  max-height: 300px;
`;

const Box = styled.div`
  background-color: ${colors.White};
`;

const Contacts = styled.div`
    flex: 1;
    margin-top: -5rem;
    background-color: ${colors.White};
    color: ${colors.Primary};
    & a, & a:visited {
        padding: .5rem 0;
        color: ${colors.Primary};
    }
    a:hover {
        color: ${colors.Color1};
    }
    & .mobile {
        gap: 1rem;
    }

    @media (max-width: ${devices.tablet}) {
        margin-top: -5rem;
        & .mobile {
            flex-direction: column;
        }
    }
`;

const Footer = () => {
    const [email, setEmail] = useState('');
    
    return (
        <>
            <Map />
            <Box>
                <Content>
                    <Row className='wrap'>
                        <Contacts className='ph-30'>
                            <Col>
                                <Row className='mobile pt-20'>
                                    <Link to={'/PhysicalSecurity'}>Physical Security</Link>
                                    <Link to={'/CashManagement'}>Cash Management</Link>
                                    <Link to={'/SoftwareAndServices'}>Software and Services</Link>
                                    <Link to={'/IoTSolutions'}>IoT Solutions</Link>
                                </Row>
                            </Col>
                            <HR className='mv-20'/>
                            <Row className='wrap'>
                                <Col className='flex-align-left mb-20'>
                                    <b>Head office</b>
                                    <span>Ace Solutions AS</span>
                                    <span>Østre Aker vei 206,</span>
                                    <span>0975 Oslo,</span>
                                    <span>Norway</span>
                                </Col>
                                <Col className='flex-align-left mb-20'>
                                    <b>Postal & Visitor address</b>
                                    <span>Ace Solutions AS</span>
                                    <span>Østre Aker vei 206,</span>
                                    <span>0975 Oslo,</span>
                                    <span>Norway</span>
                                </Col>
                                <Col className='flex-align-left mb-20'>
                                    <b>Email</b>
                                    <Link to={'mailto:info@acesolutions.no'}>info@acesolutions.no</Link>
                                    <b>Phone</b>
                                    <Link to={'tel:+4722900300'}>+47 22 90 03 00</Link>
                                </Col>
                            </Row>
                        </Contacts>
                    </Row>
                </Content>
                {/* <ScrollBar image={source} padding="5rem 0 1rem 0" color={colors.Color3} /> */}
            </Box>
        </>
    );
}

export default Footer;
