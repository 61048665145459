import React from 'react';
import styled from 'styled-components';
import { colors, devices } from '../../assets/variables';
import '../../assets/style/global.scss'
import Button from '../../components/Button';

const Category = ({
  title = null,
  description = null,
  link = null,
  image = null
}) => {
  const Box = styled.div`
    display: flex;
    flex-direction: column-reverse;
    color: ${colors.White};
    aspect-ratio: 3 / 4; 
    background: ${colors.Color1} url(${image}) center center no-repeat;
    background-size: cover;
    overflow: hidden;
    transition: all .5s ease-out;
    border-radius: 5px;
    .details {
      padding: 1rem 1rem 1.3rem 1rem;
      background: linear-gradient(180deg, rgba(0,0,0,0) 0%, ${colors.Color0} 100%);
      h2 {
        font-size: 1.5rem;
        line-height: 1.6rem;
        border-left: 3px solid ${colors.Color1};
        padding-left: .5rem;
        text-shadow: 2px 2px 5px ${colors.Color0};
      }
      p {
        font-size: .7rem;
        line-height: .9rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 3; /* Limit to 3 lines */
        max-height: calc(.75rem * 3);
        text-shadow: 2px 2px 5px ${colors.Color0};
      }
      .button {
        font-size: .7rem;
        padding: .4rem 1rem;
      }
    }
    @media (max-width: ${devices.mobile}) {
      aspect-ratio: auto;
    }
  `;

  return (
    <Box>
      <div className='details'>
        {title && <h2 className='mb-10' dangerouslySetInnerHTML={{ __html: title }} />}
        {description && <p className='mb-15' dangerouslySetInnerHTML={{ __html: description }} />}
        {link && <Button className='button transparent' link={`${link}`}>Our products</Button>}
      </div>
    </Box>
  );
}

export default Category;
