import React from 'react';
import styled from 'styled-components';
import { colors, devices } from '../assets/variables';
import home_hero from '../assets/images/jpg/home_hero.jpg';

import gunnebo from '../assets/images/png/gunnebo.png';
import ctcoin from '../assets/images/png/ctcoin.png';
import kongsberg from '../assets/images/png/kongsberg.png';
import sesami from '../assets/images/png/sesami.png';
import upkip from '../assets/images/png/upkip.png';

import { Content, Row, Col } from '../sections/UI';
import '../assets/style/global.scss'
import HomeHero from '../components/HomeHero';
import ProductsCarousel from '../components/ProductsCarousel';
import ProductCategories from '../components/ProductCategories';
import Footer from '../components/Footer';
import { Fade } from "react-awesome-reveal";

const Background = styled.div`
  background: ${colors.Color0} url(${home_hero}) center center no-repeat;
  background-size: cover;
  min-height: calc(100vh - 3.5rem);
`;

const HomeContent = styled.div`
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: start;

  & .split { gap: 10px; flex-wrap: wrap; }

  @media (max-width: ${devices.tablet}) {
    flex-direction: column;
    gap: 15px;
    .maxw-30,
    .maxw-70 { max-width: 100% !important; }
  }
`;

const Copyright = styled.div`
  text-align: center;
  opacity: .8;
`;

const Image = styled.img`
  height: 20px;
  opacity: 0.3;
  transition: all .3s ease-in;   
  &:hover { opacity: 0.7; }
  @media (max-width: ${devices.tablet}) {
    max-width: 100px;
  }
`;

const Video = styled.video`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  z-index: 0;
`;

function Home() {
  return (
    <>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <Background>
        <Content>
          <HomeHero />
          <HomeContent className='pt-10'>
              <div className='maxw-30'>
                <small>Ace Solutions is a global leader in security solutions, offering innovative products and services to control the flow of people and to protect valuables from burglary, fire and explosion.</small>
              </div>
              <div className='maxw-70'>
                <small>Our partners:</small>
                <Row className='mt-10 mb-10 split'>
                  <Image src={gunnebo} />
                  <Image src={sesami} />
                  <Image src={kongsberg} />
                  <Image src={ctcoin} />
                  <Image src={upkip} />
                </Row>
              </div>
          </HomeContent>
          <Copyright><small>Copyright &copy; 2024.</small></Copyright>
        </Content>
      </Background>
    </Fade>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <Background>
          <ProductsCarousel />
      </Background>
    </Fade>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <ProductCategories />
    </Fade>
    <Footer />
    </>
  );
}

export default Home;
