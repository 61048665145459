import React from 'react';
import styled from 'styled-components';
import { colors, devices } from '../assets/variables';
import '../assets/style/global.scss'
import { Col, Content, Row } from '../sections/UI';
import Footer from '../components/Footer';
import PageHero from '../components/PageHero';
import iot001 from '../assets/images/jpg/iot001.jpg';
import iot002 from '../assets/images/jpg/iot002.jpg';
import upkip from '../assets/images/png/upkip.png';
import Button from '../components/Button';
import FooterHero from '../components/FooterHero';
import { Fade } from "react-awesome-reveal";

const Section = styled.div`
    background-color: ${colors.White};
    color: ${colors.Primary};
    & .wrap { align-items: start; }
    & p { padding-bottom: .5rem; }
    @media (max-width: ${devices.tablet}) {
        & .maxw-30,
        & .maxw-40,
        & .maxw-50,
        & .maxw-60,
        & .maxw-70 { max-width: 100% !important; }
        & .wrap { justify-content: center; }
    }
`;

const Image = styled.img`
    -o-object-fit: cover;
    object-fit: cover;
    height: 50vh;
    @media (max-width: ${devices.tablet}) {
      height: 40vh;
      width: 100%;
      &.maxw-35 { max-width: calc(100% - 6px) !important; }
    }
`;

function IoTSolutions() {
  return (
    <>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <PageHero
        title="IoT Solutions"
        description="Ace Solutions's offering includes next-generation safe deposit lockers, designed to protect your valuables against unauthorised access, and providing a 24/7 user-friendly self-service to your customers."
      />
    </Fade>
    <Section>
      <Content className='pv-20'>
        <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
          <Row className='wrap'>
            <h2 className='maxw-70 pb-20'>Remote control of Deposit Lockers & Boxes</h2>
            <Button link={'/shop'} className='mb-20'>Our offering</Button>
          </Row>
          <Row className='wrap mt-20'>
            <p><b>Ace Solutions</b>'s IoT (Internet of Things) solutions leverage advanced connectivity and smart technologies to enhance security, efficiency, and operational insights across various industries. By integrating IoT capabilities into their products, <b>Ace Solutions</b> offers innovative solutions that provide real-time monitoring, data analytics, and remote management.</p>
            <div className='mt-20 maxw-60 pb-10'>
              <h3 className='pb-10'>Key Features of Ace Solutions's IoT Solutions:</h3>
              <ol>
                <li>
                  <b>Real-Time Monitoring and Alerts</b>:
                  <ul>
                    <li><b>Proactive Security</b>: IoT-enabled devices can monitor security systems in real time, sending instant alerts to designated personnel in case of unauthorized access, tampering, or other security breaches.</li>
                    <li><b>Operational Efficiency</b>: Continuous monitoring of equipment and systems helps ensure optimal performance and immediate detection of issues.</li>
                  </ul>
                </li>
                <li>
                  <b>Data Analytics and Insights</b>:
                  <ul>
                    <li><b>Predictive Maintenance</b>: IoT sensors collect data on equipment usage and performance, allowing predictive maintenance to prevent downtime and extend the lifespan of assets.</li>
                    <li><b>Business Intelligence</b>: Data collected from various IoT devices can be analyzed to gain insights into operational patterns, customer behavior, and overall system performance.</li>
                  </ul>
                </li>
                <li>
                  <b>Remote Management</b>:
                  <ul>
                    <li><b>Centralized Control</b>: IoT solutions enable centralized management of multiple devices and systems from a single platform, improving operational control and flexibility.</li>
                    <li><b>Remote Access</b>: Authorized personnel can access and manage systems remotely, enhancing convenience and response times.</li>
                  </ul>
                </li>
                <li>
                  <b>Integration with Existing Systems:</b>:
                  <ul>
                    <li><b>Seamless Connectivity</b>: <b>Ace Solutions</b>'s IoT solutions are designed to integrate with existing security and management systems, ensuring a seamless transition and enhanced functionality.</li>
                    <li><b>Scalability</b>: IoT solutions can be scaled to meet the needs of growing businesses, allowing for the addition of new devices and capabilities as required.</li>
                  </ul>
                </li>
              </ol>
            </div>
            <Image className='mt-20 maxw-35' src={iot001} alt="" />
          </Row>
          <Row className='wrap reverse mv-20'>
            <div className='maxw-60'>
              <h3 className='pb-10'>Applications of Ace Solutions's IoT Solutions:</h3>
              <ol>
                <li>
                  <b>Smart Safes and Cash Management</b>:
                  <ul>
                    <li><b>Cash Handling</b>: IoT-enabled smart safes and cash recyclers provide real-time tracking of cash deposits, withdrawals, and storage, improving security and operational efficiency.</li>
                    <li><b>Remote Monitoring</b>: Businesses can monitor cash levels and safe status remotely, enabling better cash flow management and reducing the risk of theft.</li>
                  </ul>
                </li>
                <li>
                  <b>Access Control Systems</b>:
                  <ul>
                    <li><b>Building Security</b>: IoT-based access control systems allow for real-time monitoring and management of entry points, ensuring secure access to facilities.</li>
                    <li><b>User Authentication</b>: Advanced authentication methods, including biometric verification, enhance security and prevent unauthorized access.</li>
                  </ul>
                </li>
                <li>
                  <b>Surveillance and Monitoring</b>:
                  <ul>
                    <li><b>CCTV Systems</b>: IoT-enabled CCTV cameras provide real-time video feeds and analytics, helping businesses monitor and secure their premises effectively.</li>
                    <li><b>Environmental Monitoring</b>: IoT sensors can monitor environmental conditions such as temperature, humidity, and air quality, ensuring optimal conditions and compliance with regulations.</li>
                  </ul>
                </li>
                <li>
                  <b>Retail Solutions</b>:
                  <ul>
                    <li><b>Customer Insights</b>: IoT devices can collect data on customer behavior and preferences, helping retailers optimize store layouts, product placements, and marketing strategies.</li>
                    <li><b>Inventory Management</b>: Real-time tracking of inventory levels and movement helps retailers manage stock efficiently and reduce losses.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Facility Management</b>:
                  <ul>
                    <li><b>Energy Management</b>: IoT solutions can monitor and control energy usage in buildings, leading to significant cost savings and improved sustainability.</li>
                    <li><b>Smart Lighting and HVAC</b>: Automated control of lighting and HVAC systems based on occupancy and usage patterns enhances comfort and reduces energy consumption.</li>
                  </ul>
                </li>
              </ol>
            </div>
            <Image className='maxw-35' src={iot002} alt="" />
          </Row>
          <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
            <div className='section invert p-20 mv-30'>
              <img src={upkip} alt="upkip" />
              <h3 className='pt-20 pb-10'>Upkip Platform - Array of Powerful Features</h3>
              <p>Workflow architecture that utilize Azure services and can integrate local and cloud-based services. Model based approach to process definitions, execution and monitoring. Low-code tools that customers can use to configure and analyze processes.</p>
              <p>Allows for integrated processes across ecosystems and value chains. Manufacturing, Supply Chain, Service partners, End customers and more. Industry best practice workflow models included for event-based maintenance and service management.</p>
              <p>Upkip basis modules allow for fast and cost-efficient development of connected products and services. Smart manufacturing modules are available with event-based maintenance and operational KPI’s.</p>
            </div>
          </Fade>
          <h3 className='pb-10'>Benefits of Ace Solutions's IoT Solutions:</h3>
          <ul>
            <li><b>Enhanced Security</b>: Real-time monitoring and alerts improve response times and reduce the risk of security breaches.</li>
            <li><b>Operational Efficiency</b>: Automation and remote management capabilities streamline operations and reduce manual intervention.</li>
            <li><b>Cost Savings</b>: Predictive maintenance and efficient resource management lead to significant cost reductions.</li>
            <li><b>Data-Driven Decisions</b>: Access to detailed analytics and insights allows businesses to make informed decisions and optimize operations.</li>
          </ul>
          <h3 className='mt-20 pb-10'>Conclusion</h3>
          <p><b>Ace Solutions</b>'s IoT solutions provide a robust framework for enhancing security, efficiency, and operational control across various industries. By integrating advanced IoT technologies into their products, <b>Ace Solutions</b> helps businesses stay ahead in a rapidly evolving technological landscape, ensuring safety, efficiency, and sustainability.</p>
          <Col className='mv-20'>
            <Button link={'/shop'} className='mt-20'>Our offering</Button>
          </Col>
        </Fade>
      </Content>
    </Section>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <FooterHero />
    </Fade>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <Footer />
    </Fade>
    </>
  );
}

export default IoTSolutions;

