import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { colors, devices } from '../assets/variables';
import { Col, Content, Row } from '../sections/UI';
import girl from '../assets/images/png/business-girl.png';
import { Link } from 'react-router-dom';

const Background = styled.div`
    background-color: ${colors.Color1};
    min-height: 10rem;
    img {
        margin-top: -8rem;
    }
    @media (max-width: ${devices.tablet}) {
        display: none;
    }
`;

const H2 = styled(Link)`
    font-size: 3rem;
    line-height: 3.5rem;
    font-weight: 700;
`;

const FooterHero = ({title = "...", description = "..."}) => {
    
    return (
        <>
        <Background>
            <Content>
                <Row className='wrap reverse'>
                    <H2 to={'mailto:info@acesolutions.no'}>info@acesolutions.no</H2>
                    <img src={girl} alt="" className='maxw-30' />
                </Row>
            </Content>
        </Background>
        </>
    );
}

export default FooterHero;
