import React from 'react';
import Slider from "react-slick";
import styled from 'styled-components';
import { colors, devices } from '../assets/variables';
import { Row } from '../sections/UI';
import Button from './Button';
import { IoChevronForwardOutline, IoChevronBackOutline  } from "react-icons/io5";
import psHero from '../assets/images/png/speedgate01.png';
import cmHero from '../assets/images/png/business-girl.png';
import ssHero from '../assets/images/png/software.png';
import iotHero from '../assets/images/png/iot.png';

const Carousel = styled(Slider)`
  display: block;
  width: 100%;
  height: 100%;

  .slick-arrow {
    padding: 1rem;
    border-radius: 200px;
    margin: 0.5rem -4rem;
    &.slick-prev {
      transform: rotate(180deg);
      margin: -1rem 0 0 -4rem;
    }
    &:hover {
      background-color: ${colors.Color1};
    }

    @media (max-width: ${devices.mobile}) {
      top: calc(100% - 1rem);
      margin: 0.5rem 0.5rem;
      &.slick-prev {
        margin: -1rem 0 0 0.5rem;
      }
    }
  }

  .slick-dots {
    bottom: 0px;
    li {
      width: auto;
      height: 5px;
      & .dot {
        background-color: ${colors.TransparentWhite};
        width: 5vw;
        height: 3px;
        border-radius: 50px;
      }
      &.slick-active .dot {
        background-color: ${colors.Color1};
      }
    }
  }
`;

const Slide = styled(Row)`
  min-height: calc(80vh - 3.5rem);
  padding: 1rem 0;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  H1, p { max-width: 90%; }

  @media (max-width: ${devices.mobile}) {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    H1, p { max-width: 100%; }
  }
`;

const Image = styled.img`
  max-height: 50vh;
  max-width: 40%;
  -o-object-fit: contain;
  object-fit: contain;
  opacity: 0.4;

  @media (max-width: 1024px) {
  }
`;

function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <IoChevronForwardOutline
      className={className}
      style={{ ...style, display: "block", color: colors.White}}
      onClick={onClick}
    />
  );
}

const HomeHero = () => {
  var settings = {
    dots: true,
    fade: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    initialSlide: 0,
    cssEase: "linear",    
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    customPaging: i => (
      <div className='dot' />
    )
  };
  
  return (
    <Carousel {...settings}>
    <Slide>
      <div>
        <h1 className='mb-20'>Physical Security</h1>
        <p className='mb-20'>Our range of entrance control gates are designed to blend in with the aesthetic of their surroundings, making users feel welcome and unobstructed while providing robust security.</p>
        <Button link={'/PhysicalSecurity'} className="pulse">Explore solutions</Button>
      </div>
      <Image src={psHero} alt='' />
    </Slide>
    <Slide>
      <div>
        <h1 className='mb-20'>Cash Management</h1>
        <p className='mb-20'>SafePay replaces normal tills with a smart recycling system which protects cash from payment all the way to the cash-counting centre. Automatic reconciliation and no cash differences.</p>
        <Button link={'/CashManagement'}>Explore solutions</Button>
      </div>
      <Image src={cmHero} alt='' />
    </Slide>
    <Slide>
      <div>
        <h1 className='mb-20'>Software and Services</h1>
        <p className='mb-20'>EntraLinq Plus extends the app’s capabilities, offering advanced features and a heightened level of control over Gunnebo products, making it a valuable investment for users with more extensive requirements.</p>
        <Button link={'/SoftwareAndServices'}>Explore solutions</Button>
      </div>
      <Image src={ssHero} alt='' />
    </Slide>
    <Slide>
      <div>
        <h1 className='mb-20'>IoT Solutions</h1>
        <p className='mb-20'>Gunnebo's offering includes next-generation safe deposit lockers, designed to protect your valuables against unauthorised access, and providing a 24/7 user-friendly self-service to your customers.</p>
        <Button link={'/IoTSolutions'}>Explore solutions</Button>
      </div>
      <Image src={iotHero} alt='' />
    </Slide>
    </Carousel>
  );
}

export default HomeHero;
