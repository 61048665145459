import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { colors, devices } from '../../assets/variables';
import '../../assets/style/global.scss'
import { Col, Content, Row } from '../../sections/UI';
import Footer from '../../components/Footer';
import PageHero from '../../components/PageHero';
import FooterHero from '../../components/FooterHero';
import SafeDepositBoxSystems from '../../assets/images/shop/categories/safe-deposit-box-systems.jpg';
import StrongRooms from '../../assets/images/shop/categories/strong-rooms.jpeg';
import VaultDoors from '../../assets/images/shop/categories/vault-doors.jpeg';
import HighSecurityLocks from '../../assets/images/shop/categories/high-security-locks.jpeg';
import SafesAndSecurityCabinets from '../../assets/images/shop/categories/safes-and-security-cabinets.jpeg';
import DepositLockers from '../../assets/images/shop/categories/deposit-lockers.jpg';

import { Fade } from "react-awesome-reveal";
import Category from './category';
import Product from './product';
import Details from './details';

import Categories from '../../api/categories.json';

import Products from '../../api/products.json';

const Section = styled.div`
    background-color: ${colors.White};
    & .wrap { align-items: start; }
    & p { padding-bottom: .5rem; }
    @media (max-width: ${devices.tablet}) {
        & .maxw-30,
        & .maxw-40,
        & .maxw-50,
        & .maxw-60,
        & .maxw-70 { max-width: 100%; }
        & .wrap { justify-content: center; }
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    @media (max-width: ${devices.laptop}) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: ${devices.tablet}) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: ${devices.mobile}) {
      grid-template-columns: 1fr;
    }
`;

const Breadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  a, a:visited {
    font-size: .7rem;
    font-weight: 400;
    color: ${colors.Color0};
    margin: 0rem 0 1rem 0;
    padding: 0 0.5rem;
    border-left: 1px solid ${colors.Color1};
    transition: all .5s ease-out;
    &:hover {
      color: ${colors.Color1};
    }
    &:first-child {
      padding: 0 0.5rem 0 0rem;
      border: none;
    }
  }
`;

const NoProducts = styled.div`
  color: ${colors.Color0};
`;

function Shop() {
  const location = useLocation();
  const [categories, setCategories] = useState(null);
  const [products, setProducts] = useState(null);
  const [urlParams, setUrlParams] = useState(new URLSearchParams(window.location.search));
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    setCategories(Categories);
  }, []);

  useEffect(() => {
    setUrlParams(new URLSearchParams(window.location.search));
  }, [location]);

  useEffect(() => {
    setSelectedCategory(urlParams.get('category') ? Categories.find(item => item.code === urlParams.get('category')) : null);
    setProducts(urlParams.get('category') ? Products.filter(p => p.category === urlParams.get('category')) : null);
    setSelectedProduct(urlParams.get('product') ? Products.find(item => item.code === urlParams.get('product')) : null);
  }, [urlParams]);

  useEffect(() => {
    selectedCategory && setProducts(Products.filter(p => p.category === selectedCategory?.code));
  }, [selectedCategory]);

  return (
    <>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      { !selectedCategory && !selectedProduct && <PageHero
        title="Grow your business with our products"
        description="Ace Solutions's extensive range of products caters to various security needs, from cash management and access control to high-security safes and surveillance systems. By integrating advanced technology and offering tailored solutions, Ace Solutions helps businesses and organizations protect their assets, enhance operational efficiency, and ensure the safety of their premises."
      /> }
      { selectedCategory && !selectedProduct && <PageHero
        title={selectedCategory.title}
        description={selectedCategory.description}
      /> }
      { selectedCategory && selectedProduct && <PageHero
        title={selectedProduct.title}
        description={selectedProduct.description}
        images={selectedProduct.images}
      /> }
    </Fade>
    <Section>
      <Content className='pv-20'>
        <Breadcrumb>
          <Link to={'/shop/'}>Shop</Link>
          { selectedCategory && <Link to={`/shop/?category=${selectedCategory.code}`}>{selectedCategory.title}</Link> }
          { selectedProduct && <Link to={`/shop/?category=${selectedCategory.code}&product=${selectedProduct.code}`}>{selectedProduct.title}</Link> }
        </Breadcrumb>          
        { !selectedCategory && categories &&
          <Grid className='mb-80'>
            { categories.map((item) => {
              return (<Category key={item.id}
                title={item.title}
                description={item.description}
                link={`/shop/?category=${item.code}`}
                image={item.image}
              />)
            })}
          </Grid>
        }          
        { selectedCategory && products && !selectedProduct && 
          <Grid className='mb-80'>
            { products.map((item) => {
            return (<Product key={item.id}
              title={item.title}
              description={item.description}
              link={`/shop/?category=${selectedCategory?.code}&product=${item.code}`}
              image={item.image}
            />)
          })}
          </Grid>
          // https://www.chubbsafes.com/en/products/proguard-dt/
        }
        { selectedCategory && products && !selectedProduct && Object.keys(products).length === 0 &&
          <Grid className='mb-80'><NoProducts>No products ...</NoProducts></Grid>
          
        } 
        { selectedCategory && selectedProduct && <Details product={selectedProduct} /> } 
      </Content>
    </Section>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <FooterHero />
    </Fade>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <Footer />
    </Fade>
    </>
  );
}

export default Shop;
