import React from 'react';
import styled from 'styled-components';
import { colors } from '../assets/variables';

const Control = styled.input`
    background-color: ${colors.Color3};
    color: ${colors.White};
    border: 2px solid ${colors.White};
    padding: .5rem;
    white-space: nowrap;
    width: calc(100% - 1rem);
`;

function Input({onChange, type = "text", value = "", className = "", children}) {
  return (
    <Control className={className} value={value} onChange={(e) => onChange(e.target.value)} type={type} >
        {children}
    </Control>
  );
}

export default Input;
