import React from 'react';
import styled from 'styled-components';
import { colors, devices } from '../../assets/variables';
import '../../assets/style/global.scss'
import Button from '../../components/Button';
import { Row } from '../../sections/UI';

const Product = ({
  title = null,
  description = null,
  link = null,
  image = null
}) => {
  const Box = styled.div`
    display: flex;
    flex-direction: column-reverse;
    color: ${colors.White};
    aspect-ratio: 1; 
    background: ${colors.TransparentBlack} url(${image}) center center no-repeat;
    background-size: cover;
    overflow: hidden;
    transition: all .5s ease-out;
    border-radius: 5px;
    .details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 1rem 1rem 1.3rem 1rem;
      background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, ${colors.Color0} 100%);
      h2 {
        font-size: 1.5rem;
        line-height: 1.6rem;
        text-shadow: 2px 2px 5px ${colors.Color0};
      }
      p {
        font-size: .7rem;
        line-height: .9rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 3; /* Limit to 3 lines */
        max-height: calc(.75rem * 3);
        text-shadow: 2px 2px 5px ${colors.Color0};
      }
      .button {
        width: 35%;
        font-size: .7rem;
        padding: .4rem 1rem;
      }
    }
    /* @media (max-width: ${devices.mobile}) {
      aspect-ratio: auto;
    } */
  `;

  return (
    <Box>
      { title !== '' && <div className='details'>
        {title && <h2 className='mb-10' dangerouslySetInnerHTML={{ __html: title }} />}
        <div>
          {description && <p className='mb-15' dangerouslySetInnerHTML={{ __html: description }} />}
          <Row>
            {link && <Button className='button transparent' link={`${link}`}>Details</Button>}
            <Button className='button primary' link={`tel:+4722900300`}>Get in touch</Button>
          </Row>
        </div>
      </div>}
    </Box>
  );
}

export default Product;
