import React from 'react';
import styled from 'styled-components';
import { colors, devices } from '../assets/variables';
import '../assets/style/global.scss'
import { Col, Content, Row } from '../sections/UI';
import Footer from '../components/Footer';
import PageHero from '../components/PageHero';
import gate001 from '../assets/images/jpg/gate001.jpg';
import gate002 from '../assets/images/jpg/gate002.jpg';
import Button from '../components/Button';
import FooterHero from '../components/FooterHero';
import { Fade } from "react-awesome-reveal";

const Section = styled.div`
    background-color: ${colors.White};
    color: ${colors.Primary};
    & .wrap { align-items: start; }
    & p { padding-bottom: .5rem; }
    @media (max-width: ${devices.tablet}) {
        & .maxw-30,
        & .maxw-40,
        & .maxw-50,
        & .maxw-60,
        & .maxw-70 { max-width: 100% !important; }
        & .wrap { justify-content: center; }
    }
`;

const Image = styled.img`
    /* box-shadow:0px 0px 0px 3px ${colors.Color4} inset;
    padding: 3px; */
    @media (max-width: ${devices.tablet}) {
        &.maxw-35 { max-width: calc(100% - 6px) !important; }
    }
`;

function PhysicalSecurity() {
  return (
    <>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <PageHero
        title="Physical Security"
        description="Our range of entrance control gates are designed to blend in with the aesthetic of their surroundings, making users feel welcome and unobstructed while providing robust security."
      />
    </Fade>
    <Section>
      <Content className='pv-20'>
        <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
          <Row className='wrap'>
            <h2 className='maxw-70 pb-20'>Sleek and intuitive entrance control for a range of use cases</h2>
            <Button link={'/shop'} className='mb-20'>Our offering</Button>
          </Row>
          <p>
          <b>Ace Solutions</b>'s security and speed gates are advanced access control solutions designed to manage and secure entry points in various environments, including corporate offices, airports, public transport stations, and other high-traffic areas. These gates are engineered to provide both security and efficient throughput, ensuring that authorized individuals can enter quickly while preventing unauthorized access.</p>
          <Row className='wrap mt-20'>
            <div className='maxw-60 pb-10'>
              <h3 className='pb-10'>Key Features of Ace Solutions's Security and Speed Gates:</h3>
              <ol>
                <li>
                  <b>Access Control Integration</b>:
                  <ul>
                    <li><b>Authentication Methods</b>: These gates support various authentication methods, including RFID cards, biometrics (fingerprint and facial recognition), QR codes, and mobile credentials.</li>
                    <li><b>Seamless Integration</b>: They can be integrated with existing access control systems, ensuring seamless functionality within established security infrastructures.</li>
                  </ul>
                </li>
                <li>
                  <b>High Throughput</b>:
                  <ul>
                    <li><b>Rapid Operation</b>: Speed gates are designed for fast and efficient operation, minimizing delays during peak times. They typically allow multiple people to pass through per minute.</li>
                    <li><b>Smooth Flow</b>: The gates are equipped with sensors and intelligent algorithms to manage the flow of people, ensuring smooth and uninterrupted passage.</li>
                  </ul>
                </li>
                <li>
                  <b>Enhanced Security</b>:
                  <ul>
                    <li><b>Anti-Tailgating</b>: Advanced detection sensors prevent tailgating and piggybacking, ensuring that only one person enters per authentication.</li>
                    <li><b>Alarm Systems</b>: Integrated alarm systems trigger alerts in case of unauthorized access attempts or breaches.</li>
                    <li><b>High-Security Models</b>: For high-security areas, <b>Ace Solutions</b> offers gates with reinforced barriers and additional security features.</li>
                  </ul>
                </li>
                <li>
                  <b>Design and Aesthetics</b>:
                  <ul>
                    <li><b>Customizable Design</b>: These gates are available in various designs and finishes, allowing customization to match the aesthetic of the installation environment.</li>
                    <li><b>Compact Footprint</b>: They are designed to fit in a compact space without compromising on functionality, making them suitable for areas with space constraints.</li>
                  </ul>
                </li>
                <li>
                  <b>Durability and Reliability</b>:
                  <ul>
                    <li><b>Robust Construction</b>: Made from high-quality materials, <b>Ace Solutions</b>'s gates are built to withstand heavy usage and harsh environments.</li>
                    <li><b>Low Maintenance</b>: Designed for minimal maintenance, these gates ensure long-term reliability and reduced operational costs.</li>
                  </ul>
                </li>
              </ol>
            </div>
            <Image className='maxw-35' src={gate001} alt="" />
          </Row>
          <Row className='wrap reverse mv-20'>
            <div className='maxw-60 mb-20'>
              <h3 className='pb-10'>Types of Security and Speed Gates by Ace Solutions:</h3>
              <ol>
                <li>
                  <b>Speed Gates</b>:
                  <ul>
                    <li><b>Fastlane Series</b>: These gates provide high-speed throughput and are ideal for locations requiring quick and secure access, such as office buildings and transportation hubs.</li>
                    <li><b>Swing Gates</b>: Featuring swing barriers, these gates offer a wider passage for improved accessibility, suitable for both standard and wheelchair access.</li>
                  </ul>
                </li>
                <li>
                  <b>Turnstiles</b>:
                  <ul>
                    <li><b>Full-Height Turnstiles</b>: Ideal for high-security environments, these turnstiles provide maximum security and control over entry and exit points.</li>
                    <li><b>Half-Height Turnstiles</b>: Suitable for supervised areas, these turnstiles offer a balance between security and convenience.</li>
                  </ul>
                </li>
                <li>
                  <b>Retractable Gates</b>:
                  <ul>
                    <li><b>Flap Barriers</b>: Featuring retractable flaps, these gates are designed for quick opening and closing, ensuring high throughput and enhanced security.</li>
                    <li><b>Sliding Gates</b>: These gates offer a sleek design and smooth operation, making them suitable for premium environments.</li>
                  </ul>
                </li>
              </ol>
            </div>
            <Image className='maxw-35 pb-10' src={gate002} alt="" />
          </Row>
          <h3 className='pb-10'>Applications of Ace Solutions's Security and Speed Gates:</h3>
          <ol>
            <li>
              <b>Corporate Offices</b>:
              <ul>
                <li><b>Employee Access</b>: Managing employee entry and exit efficiently while ensuring security.</li>
                <li><b>Visitor Management</b>: Integration with visitor management systems for controlled visitor access.</li>
              </ul>
            </li>
            <li>
              <b>Airports and Public Transport</b>:
              <ul>
                <li><b>Passenger Flow</b>: Ensuring quick and secure access for passengers while preventing unauthorized entry.</li>
                <li><b>Staff Access</b>: Controlling access to restricted areas for staff and authorized personnel.</li>
              </ul>
            </li>
            <li>
              <b>Educational Institutions</b>:
              <ul>
                <li><b>Student and Staff Access</b>: Managing access for students, faculty, and staff, enhancing campus security.</li>
                <li><b>Event Control</b>: Controlling entry during events and ensuring only authorized individuals can access specific areas.</li>
              </ul>
            </li>
            <li>
              <b>Healthcare Facilities</b>:
              <ul>
                <li><b>Patient and Staff Security</b>: Ensuring secure access for patients, visitors, and staff, protecting sensitive areas.</li>
                <li><b>Emergency Situations</b>: Facilitating quick evacuation and controlled access during emergencies.</li>
              </ul>
            </li>
          </ol>
          <h3 className='mt-20 pb-10'>Benefits of Ace Solutions's Security and Speed Gates:</h3>
          <ul>
            <li><b>Improved Security</b>: Prevents unauthorized access and enhances overall security of premises.</li>
            <li><b>Efficient Throughput</b>: Allows rapid processing of individuals, reducing wait times and improving user experience.</li>
            <li><b>Scalability</b>: Solutions can be scaled and customized to meet the needs of different environments and security requirements.</li>
            <li><b>Aesthetic Integration</b>: Customizable designs ensure the gates blend seamlessly with the architectural aesthetics of the installation site.</li>
          </ul>
          <h3 className='mt-20 pb-10'>Conclusion</h3>
          <p><b>Ace Solutions</b>'s security and speed gates provide a sophisticated solution for managing access control efficiently and securely. With their advanced features, robust construction, and seamless integration capabilities, these gates are ideal for a wide range of applications, ensuring both security and convenience for users.</p>
          <Col className='mv-20'>
            <Button link={'/shop'} className='mt-20'>Our offering</Button>
          </Col>
        </Fade>
      </Content>
    </Section>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <FooterHero />
    </Fade>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <Footer />
    </Fade>
    </>
  );
}

export default PhysicalSecurity;
