import React from 'react';
import styled from 'styled-components';
import { colors } from '../assets/variables';
import '../assets/style/global.scss'
import { Col, Content, Row } from '../sections/UI';
import Footer from '../components/Footer';
import PageHero from '../components/PageHero';
import cash001 from '../assets/images/jpg/cash001.jpg';
import cash002 from '../assets/images/jpg/cash002.jpg';
import ctcoin from '../assets/images/png/ctcoin.png';
import Button from '../components/Button';
import FooterHero from '../components/FooterHero';

import { Fade } from "react-awesome-reveal";

const Section = styled.div`
    background-color: ${colors.White};
    color: ${colors.Primary};
    & .wrap { align-items: start; }
    & p { padding-bottom: .5rem; }
    @media (max-width: 1024px) {
        & .maxw-30,
        & .maxw-40,
        & .maxw-50,
        & .maxw-60,
        & .maxw-70 { max-width: 100% !important; }
        & .wrap { justify-content: center; }
    }
`;

const Image = styled.img`
    -o-object-fit: cover;
    object-fit: cover;
    width: 50%;
    height: 30vh;
    @media (max-width: 1024px) {
    }
`;

function CashManagement() {
  return (
    <>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <PageHero
        title="Cash Management"
        description="SafePay replaces normal tills with a smart recycling system which protects cash from payment all the way to the cash-counting centre. Automatic reconciliation and no cash differences."
      />
    </Fade>
    <Section>
      <Content className='pv-20'>
        <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
          <Row className='wrap'>
            <h2 className='maxw-70 pb-20'>Closed Cash Management</h2>
            <Button link={'/shop'} className='mb-20'>Our offering</Button>
          </Row>
          <p>Cash Management by <b>Ace Solutions</b> is a comprehensive solution designed to streamline and secure the handling of cash within businesses. <b>Ace Solutions</b>, a global leader in security products, has developed a range of products and services aimed at enhancing the efficiency, accuracy, and security of cash transactions.</p>
          <Row className='wrap mt-20'>
            <Image src={cash001} alt="" />
            <Image src={cash002} alt="" />
          </Row>
        </Fade>
        <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
          <h3 className='pt-20 pb-10'>Key Features of Ace Solutions's Cash Management Solutions:</h3>
          <ol>
            <li>
              <b>Smart Safes</b>:
              <ul>
                <li><b>Deposit Solutions</b>: Smart safes allow for quick and secure cash deposits. They are often equipped with advanced features such as counterfeit detection and real-time reporting.</li>
                <li><b>Secure Storage</b>: Safes provide a secure environment for storing cash, reducing the risk of theft and internal fraud.</li>
              </ul>
            </li>
            <li>
              <b>Cash Recyclers</b>:
              <ul>
                <li><b>Automated Handling</b>: Cash recyclers automate the acceptance, counting, sorting, and dispensing of cash. This reduces the need for manual cash handling and minimizes human errors.</li>
                <li><b>Increased Efficiency</b>: By recycling cash within the system, these machines help businesses maintain optimal cash levels, ensuring that cash is available when needed without frequent bank visits.</li>
              </ul>
            </li>
            <li>
              <b>Cash Deposit Solutions</b>:
              <ul>
                <li><b>Enhanced Security</b>: These solutions are designed to secure cash deposits, often featuring robust locking mechanisms and advanced authentication methods.</li>
                <li><b>Real-Time Monitoring</b>: Businesses can track cash deposits in real time, providing better oversight and control over cash flow.</li>
              </ul>
            </li>
            <li>
              <b>Software Solutions</b>:
              <ul>
                <li><b>Cash Control Software</b>: <b>Ace Solutions</b> offers software that integrates with their hardware solutions to provide comprehensive cash management. This includes tracking cash levels, generating reports, and providing analytics.</li>
                <li><b>Remote Monitoring</b>: Many solutions offer remote monitoring capabilities, allowing businesses to manage cash operations from a central location.</li>
              </ul>
            </li>
            <li>
              <b>End-to-End Solutions</b>:
              <ul>
                <li><b>Integration with Banking Services</b>: <b>Ace Solutions</b>'s solutions can be integrated with banking services to facilitate easy and secure cash deposits directly to the bank, reducing the need for frequent cash-in-transit services.</li>
                <li><b>Tailored Services</b>: <b>Ace Solutions</b> offers customized solutions to meet the specific needs of various industries, including retail, banking, and hospitality.</li>
              </ul>
            </li>
          </ol>
        </Fade>
        <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
          <div className='section invert p-20 mv-30'>
            <img src={ctcoin} alt="CTcoin" />
            <h3 className='pt-20 pb-10'>Coin Counters, Note Counters, Cash Deposit Systems and Sanitizers</h3>
            <p>Some of CTcoin’s core unique selling points are modern Scandinavian design, reliability, serviceability, accuracy and “best in class” performance. We may not have the broadest spectrum of product offerings, but rather, we have strategically decided that “what we do – we do best”. Hence, CTcoin’s products are market leaders in their field and renowned all over the world as being “top of the pops”.</p>
          </div>
        </Fade>
        <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
          <h3 className='pb-10'>Benefits of Ace Solutions's Cash Management:</h3>
          <ul>
            <li><b>Enhanced Security</b>: By automating cash handling and providing secure storage, <b>Ace Solutions</b> reduces the risk of theft and fraud.</li>
            <li><b>Improved Efficiency</b>: Automation and real-time tracking reduce the time spent on cash handling, allowing staff to focus on customer service and other important tasks.</li>
            <li><b>Cost Savings</b>: Efficient cash management can lead to significant cost savings by reducing the need for manual labor and minimizing cash-in-transit expenses.</li>
            <li><b>Compliance and Reporting</b>: <b>Ace Solutions</b>’s solutions help businesses maintain compliance with financial regulations by providing accurate and detailed reports on cash transactions.</li>
          </ul>
          <h3 className='pt-20 pb-10'>Conclusion</h3>
          <p><b>Ace Solutions</b>’s cash management solutions offer businesses a comprehensive and secure way to handle cash transactions. By integrating advanced technology and secure hardware, <b>Ace Solutions</b> helps businesses enhance security, improve efficiency, and reduce costs associated with cash handling.</p>
          <Col className='mv-20'>
            <Button link={'/shop'} className='mt-20'>Our offering</Button>
          </Col>
        </Fade>
      </Content>
    </Section>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <FooterHero />
    </Fade>
    <Fade triggerOnce cascade damping={0.3} fraction={0.3}>
      <Footer />
    </Fade>
    </>
  );
}

export default CashManagement;
