import Layout from "../sections/Layout";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import PhysicalSecurity from "./PhysicalSecurity";
import SoftwareAndServices from "./SoftwareAndServices";
import IoTSolutions from "./IoTSolutions";
import CashManagement from "./CashManagement";
import Shop from "./shop";

function App() {
    return (
        <Layout>
            <Routes>
                <Route path="/" element={<Home />}  />
                <Route path="/PhysicalSecurity" element={<PhysicalSecurity />}  />
                <Route path="/CashManagement" element={<CashManagement />}  />
                <Route path="/SoftwareAndServices" element={<SoftwareAndServices />}  />
                <Route path="/IoTSolutions" element={<IoTSolutions />}  />
                <Route path="/shop" element={<Shop />}  />
            </Routes>
        </Layout>
    );
}

export default App;