import React from 'react';
import styled from 'styled-components';
import { colors, devices } from '../../assets/variables';
import '../../assets/style/global.scss'
import Button from '../../components/Button';
import { Row } from '../../sections/UI';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Details = ({
  product = null
}) => {
  const Box = styled.div`
      color: ${colors.Color0};
      h3 { text-transform: uppercase; }
  `;

  return (
    <Box className='mb-100'>
      <Tabs>
        <TabList className='ace-tabs'>
          <Tab>Description</Tab>
          <Tab>Specification</Tab>
          <Tab disabled={!product.virtual}>360° View</Tab>
        </TabList>
        <TabPanel>
          {product.title && <h3 className='mv-10' dangerouslySetInnerHTML={{ __html: product.title }} />}
          {product.description && <p className='mb-15' dangerouslySetInnerHTML={{ __html: product.description }} />}
        </TabPanel>
        <TabPanel>
          <h3 className='mv-10'>Key Specifications</h3> 
          {product.specification && <div className='mb-15' dangerouslySetInnerHTML={{ __html: product.specification }} />}
        </TabPanel>
        {product.virtual && <TabPanel>
          <h3 className='mv-10'>See models in 360° view</h3>
          <Tabs>
            <TabList className='view-360'>
              { product.virtual.map((item, i) => <Tab key={i}>{item.name}</Tab>) }
            </TabList>            
            { product.virtual.map((item, i) => <TabPanel key={i}>
              <iframe src={item.link} style={{display: 'block', margin: '0px', width: '100%', height: '500px', border: 'none', overflow: 'hidden'}}></iframe>
            </TabPanel>) }
          </Tabs>
        </TabPanel>}
      </Tabs>
      <div>
      </div>
    </Box>
  );
}

export default Details;
