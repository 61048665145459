export const devices = {
    mobile: '600px',
    tablet: '1024px',
    laptop: '1200px',
    monitor: '1920px',
    wide: '2440px'
};

export const colors = {
    Color0: '#292720',
    Color1: '#FFA800',
    Color2: '#242219',
    Color3: '#212121',
    Color4: '#DDDDDD',
    Color5: '#242219',
    Primary: '#292720',
    Secondary: '#ffffff',
    Black: '#000000',
    White: '#ffffff',

    TransparentWhite: 'rgba(255,255,255,0.1)',
    TransparentBlack: 'rgba(0,0,0,0.1)',

    Gradient1: 'linear-gradient(180deg, #292720 75%, rgb(41 39 32 / 50%) 100%)',
};