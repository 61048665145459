import styled, { injectGlobal } from 'styled-components';
import { colors } from '../assets/variables';

export const Section = styled.div`
    padding: 0px;
    background-color: ${colors.Color0};
    color: ${colors.White};
    & a, & a:visited { color: ${colors.White}; }
`;

export const Content = styled.div`
    position: relative;
    width: calc(100% - 2rem);
    max-width: 1400px;
    margin: 0px auto;
`;

export const Col = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &.flex { flex: 1; }
    &.wrap { flex-wrap: wrap; }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &.flex { flex: 1; }
    &.wrap { flex-wrap: wrap; }
    &.reverse { flex-direction: row-reverse; }
`;

export const HR = styled.hr`
    background-color: rgba(0,0,0,0);
    border: none;
    border-bottom: 1px solid ${colors.Color1};
    width: 100%;
`;
