import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { colors, devices } from '../assets/variables';
import { Col, Content, Row } from '../sections/UI';
import home_hero from '../assets/images/jpg/home_hero.jpg';
import Slider from "react-slick";

const Background = styled.div`
    background: ${colors.Color0} url(${home_hero}) center center no-repeat;
    background-size: cover;
    min-height: 10rem;
    .row { gap: 10px }
    .slider-container {
        width: 100%;
        max-width: 500px;
        .slick-slide img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 5px;;
        }
    }
    h1 { max-width: 95%; text-align: left !important; }
    p { max-width: 95%; }
    @media (max-width: ${devices.tablet}) {
        h1, p { max-width: 100%; }
        .row { flex-direction: column; }
        .slider-container { max-width: calc(100% - 50px); }
        .width-40, 
        .width-60 { width: 100% !important; } 
        & .maxw-70 { max-width: 100% !important; }
    }
`;

const PageHero = ({title = null, description = null, images = null}) => {
    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false
    };
    return (
        <>
        { title && description && <Background>
            <Content>
                <Row className='row'>
                    <Col className={`flex-align-left width-60 ${!images && 'maxw-70'}`}>
                        { title && <h1 className='pt-30 pb-20' dangerouslySetInnerHTML={{ __html: title }} /> }
                        { description && !images && <p className='pb-30' dangerouslySetInnerHTML={{ __html: description }} /> }
                    </Col>
                    {images && <div className="slider-container width-40 mv-20">
                        <Slider {...settings}>
                            { images.map((item, index) => 
                                <div key={index}>
                                    <img src={item} />
                                </div>
                            ) }
                        </Slider>
                    </div>}
                </Row>
            </Content>
        </Background>}
        </>
    );
}

export default PageHero;
