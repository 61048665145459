import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../assets/style/global.scss'
import { createGlobalStyle } from 'styled-components';
import { Section } from './UI';
import Header from '../components/Header';
import { devices } from '../assets/variables';

const GlobalStyle = createGlobalStyle`
  #root {
    font-size: 16px;
    line-height: 18px;
    @media (max-width: ${devices.tablet}) {
      font-size: 14px;
      line-height: 16px;
    }
  }
`

function Layout({children}) {
  const location = useLocation();

  useEffect(() => {
    document.title = 'Ace Solutions';
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location]);

  return (
    <Section>
        <GlobalStyle  />
        <Header />
        <div className='pt-35'>
            {children}
        </div>
    </Section>
  );
}

export default Layout;